import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import { get } from "lodash-es";
import cancelClass from "@/common/cancelToken";
import NProgress from "nprogress"; // 进度条
import "nprogress/nprogress.css"; // 引入样式

const language = process.env.VUE_APP_I18N || "zh-CN";
const customLangs = require(`assets/i18n/${language}.json`);

NProgress.configure({
  easing: "ease", //anim method
  speed: 500, //speed
  showSpinner: false, //show ico
  trickleSpeed: 200, //step
  minimum: 0.3 //init size
});

//axios.defaults.withCredentials = true;
// create an axios instance
const instance = axios.create({
  baseURL: process.env.VUE_APP_SERVER,
  timeout: 20000, // request timeout
  headers: {
    "Accept-Language": process.env.VUE_APP_I18N || "zh-CN"
  }
});

//allow client create cookie
instance.defaults.withCredentials = true;

instance.interceptors.request.use(
  config => {
    config.cancelToken = cancelClass.cancelRequest.source.token;
    NProgress.start(); // show progress bar
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function(response) {
    NProgress.done(); // hide progress bar
    store.dispatch("loading", "off"); //cancel loading

    if (response.data.code !== 200) {
      switch (response.data.code) {
        case 401:
          // case 403:
          localStorage.setItem("userid", "");
          store.dispatch("setLogin", false);
          store.dispatch("setAccount", {});
          console.log("router");

          if (router.currentRoute.fullPath) {
            if (router.currentRoute.fullPath.includes("/mobile")) {
              router.push({
                path: "/mobile/logon",
                query: {
                  redirect: router.currentRoute.fullPath,
                  showLogin: true
                }
              });
            } else {
              router.push({
                path: "/",
                query: {
                  redirect: router.currentRoute.fullPath,
                  showLogin: true
                }
              });
            }
          } else {
            router.push({
              path: "/",
              query: { redirect: router.currentRoute.fullPath, showLogin: true }
            });
          }
          break;
      }
    }
    return {
      ...response,
      data: response.data.data,
      status: response.data.code,
      statusText:
        response.data.code === 200 ? "OK" : get(response.data, "errors[0]")
    };
  },
  function(error) {
    console.error("sudiaoba output: ", error);
    // code 12345 network error
    return Promise.resolve({
      code: 12345,
      statusText: customLangs["netword_error"]
    });
  }
);

Vue.prototype.$http = instance;
