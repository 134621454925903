const language = process.env.VUE_APP_I18N || "zh-CN";
const customLangs = require(`assets/i18n/${language}.json`);

export default [
  {
    path: "/",
    name: "home",
    component: () => import("views/home"),
    alias: "",
    meta: {
      pagetitle: customLangs["page_title"],
      actived: "home",
      requiresAuth: false
    }
  },
  {
    path: "/pages",
    name: "pages",
    component: () => import("views/pages"),
    children: [
      {
        path: "help/index.html",
        redirect: "help"
      },
      {
        path: "activities/index.html",
        redirect: "activities"
      },
      {
        path: "changes/index.html",
        redirect: "changes"
      },
      {
        path: "activities",
        name: "activities",
        component: () => import("views/pages/activities"),
        meta: {
          pagetitle: customLangs["page_title11"],
          actived: "activities",
          requiresAuth: false
        },
        alias: "/activities"
      },
      {
        path: "center",
        name: "center",
        component: () => import("views/pages/center"),
        meta: {
          pagetitle: customLangs["page_title1"],
          actived: "center",
          requiresAuth: true
        },
        alias: "/center",
        children: [
          {
            path: "",
            name: "homepage",
            component: () => import("views/pages/center/home-page"),
            meta: {
              pagetitle: customLangs["page_title1"],
              actived: "center",
              subactived: "1",
              requiresAuth: true
            }
          },
          {
            path: "tasks-record",
            name: "tasks-record",
            component: () => import("views/pages/center/tasks-record"),
            meta: {
              pagetitle: customLangs["page_title2"],
              actived: "center",
              subactived: "2",

              requiresAuth: true
            }
          },
          {
            path: "exchange-record",
            name: "exchange-record",
            component: () => import("views/pages/center/exchange-record"),
            meta: {
              pagetitle: customLangs["page_title3"],
              actived: "center",
              subactived: "3",

              requiresAuth: true
            }
          },
          {
            path: "medal",
            name: "medal",
            component: () => import("views/pages/center/medal"),
            meta: {
              pagetitle: customLangs["page_title15"],
              actived: "center",
              subactived: "4",
              requiresAuth: true
            }
          },
          {
            path: "messages",
            name: "messages",
            component: () => import("views/pages/center/messages"),
            meta: {
              pagetitle: customLangs["page_title4"],
              actived: "center",
              subactived: "5",

              requiresAuth: true
            }
          },
          {
            path: "gold-record",
            name: "gold-record",
            component: () => import("views/pages/center/gold-record"),
            meta: {
              pagetitle: customLangs["page_title5"],
              actived: "center",
              subactived: "6",

              requiresAuth: true
            }
          },
          {
            path: "setting",
            name: "setting",
            component: () => import("views/pages/center/setting"),
            meta: {
              pagetitle: customLangs["page_title6"],
              actived: "center",
              subactived: "7",

              requiresAuth: true
            }
          },
          {
            path: "recommend",
            name: "recommend",
            component: () => import("views/pages/center/recommend"),
            meta: {
              pagetitle: customLangs["page_title7"],
              actived: "center",
              subactived: "8",

              requiresAuth: true
            }
          },
          {
            path: "credit",
            name: "credit",
            component: () => import("views/pages/center/credit"),
            meta: {
              pagetitle: customLangs["page_title8"],
              actived: "center",
              subactived: "9",

              requiresAuth: true
            }
          },
          {
            path: "card-coupons",
            name: "card-coupons",
            component: () => import("views/pages/center/card-coupons"),
            meta: {
              pagetitle: "速调吧-卡券专区",
              actived: "center",
              subactived: "10",
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: "changes",
        name: "changes",
        component: () => import("views/pages/changes"),
        meta: {
          pagetitle: customLangs["page_title9"],
          actived: "changes",
          requiresAuth: false
        },
        alias: "/changes"
      },
      {
        path: "help",
        name: "help",
        component: () => import("views/pages/help"),
        meta: {
          pagetitle: customLangs["page_title10"],
          actived: "help",
          requiresAuth: false
        },
        alias: "/help"
      },

      {
        path: "activities/:id",
        name: "activitiedetail",
        component: () => import("views/pages/activities/detail"),
        meta: {
          pagetitle: customLangs["page_title11"],
          actived: "activities",
          requiresAuth: false
        }
      },
      {
        path: "tasks",
        name: "tasks",
        component: () => import("views/pages/tasks"),
        meta: {
          pagetitle: customLangs["page_title12"],
          actived: "tasks",
          requiresAuth: true
        },
        alias: "/tasks"
      },
      {
        path: "treasure-hunt",
        name: "treasure-hunt",
        component: () => import("views/pages/treasure-hunt"),
        meta: {
          pagetitle: "速调吧-寻宝专区",
          actived: "treasure-hunt",
          requiresAuth: true
        },
        alias: "/treasure-hunt"
      },
      {
        path: "prize-draw",
        name: "prize-draw",
        component: () => import("views/pages/prize-draw"),
        meta: {
          pagetitle: "速调吧-抽奖",
          actived: "prize-draw",
          requiresAuth: true
        },
        alias: "/prize-draw"
      },
      {
        path: "reward",
        name: "reward",
        component: () => import("views/pages/reward"),
        meta: {
          pagetitle: "速调吧-奖励",
          actived: "reward",
          requiresAuth: true
        },
        alias: "/reward"
      },
      {
        path: "survey/:id",
        name: "survey",
        component: () => import("views/pages/survey"),
        meta: {
          pagetitle: customLangs["page_title13"],
          actived: "tasks",
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: "/subPage",
    name: "subPage",
    component: () => import("views/subPage"),
    meta: {
      pagetitle: customLangs["page_title"],
      requiresAuth: false
    }
  },
  {
    path: "/brower-update",
    name: "browerUpdate",
    component: () => import("views/brower-page"),
    meta: {
      pagetitle: customLangs["page_title14"],
      requiresAuth: false
    }
  },
  {
    path: "/mobile/red-envelope",
    name: "redEnvelope",
    component: () => import("views/mobile/red-envelope"),
    meta: {
      pagetitle: "速调吧-寻宝专区",
      requiresAuth: false
    }
  },
  {
    path: "/mobile/turntable",
    name: "mobileTurntable",
    component: () => import("views/mobile/turntable"),
    meta: {
      pagetitle: "速调吧-抽奖",
      requiresAuth: false
    }
  },
  {
    path: "/mobile/reward2",
    name: "reward2",
    component: () => import("views/mobile/reward2"),
    meta: {
      pagetitle: "速调吧-奖励",
      requiresAuth: false
    }
  },
  {
    path: "/mobile",
    name: "/mobile",
    redirect: "/mobile/logon"
  },
  {
    path: "/mobile/logon",
    name: "mobileLogon",
    component: () => import("@/views/mobile/logon"),
    meta: { pagetitle: "速调吧-登录", requiresAuth: false }
  },
  {
    path: "/mobile/register",
    name: "mobileRegister",
    component: () => import("@/views/mobile/register"),
    meta: { pagetitle: "速调吧-注册", requiresAuth: false }
  },
  {
    path: "/mobile/forgot-password",
    name: "mobileForgotPassword",
    component: () => import("@/views/mobile/forgot-password"),
    meta: { pagetitle: "速调吧-忘记密码", requiresAuth: false }
  },
  {
    path: "/mobile/home",
    name: "mobileHome",
    component: () => import("@/views/mobile/home"),
    meta: { pagetitle: "速调吧-首页", requiresAuth: true }
  },
  {
    path: "/mobile/exchange",
    name: "mobileExchange",
    component: () => import("@/views/mobile/exchange"),
    meta: { pagetitle: "速调吧-兑换", requiresAuth: true }
  },
  {
    path: "/mobile/my",
    name: "mobileMy",
    component: () => import("@/views/mobile/my"),
    meta: { pagetitle: "速调吧-我的", requiresAuth: true }
  },
  {
    path: "/mobile/survey/:id",
    name: "mobileSurvey",
    component: () => import("@/views/mobile/survey"),
    meta: { pagetitle: "速调吧-问卷", requiresAuth: true }
  },
  {
    path: "/mobile/exchangeDetails",
    name: "mobileExchangeDetails",
    component: () => import("@/views/mobile/exchangeDetails"),
    meta: { pagetitle: "速调吧-奖品详情", requiresAuth: true }
  },
  {
    path: "/mobile/accountSettings",
    name: "accountSettings",
    component: () => import("@/views/mobile/accountSettings"),
    meta: { pagetitle: "速调吧-账户设置", requiresAuth: true }
  },
  {
    path: "/mobile/alipay",
    name: "/mobileAlipay",
    component: () => import("@/views/mobile/alipay"),
    meta: { pagetitle: "速调吧-支付宝设置", requiresAuth: true }
  },
  {
    path: "/mobile/changePassword",
    name: "mobileChangePassword",
    component: () => import("@/views/mobile/changePassword"),
    meta: { pagetitle: "速调吧-修改密码", requiresAuth: true }
  },
  {
    path: "/mobile/myCredit",
    name: "mobileMyCredit",
    component: () => import("@/views/mobile/myCredit"),
    meta: { pagetitle: "速调吧-我的信用值", requiresAuth: true }
  },
  {
    path: "/mobile/myCreditDetails",
    name: "mobileMyCreditDetails",
    component: () => import("@/views/mobile/myCreditDetails"),
    meta: { pagetitle: "速调吧-信用明细", requiresAuth: true }
  },
  {
    path: "/mobile/signIn",
    name: "mobileSignIn",
    component: () => import("@/views/mobile/signIn"),
    meta: { pagetitle: "速调吧-每日签到", requiresAuth: true }
  },
  {
    path: "/mobile/myAccount",
    name: "mobileMyAccount",
    component: () => import("@/views/mobile/myAccount"),
    meta: { pagetitle: "速调吧-我的账户", requiresAuth: true }
  },
  {
    path: "/mobile/couponZone",
    name: "mobileCouponZone",
    component: () => import("@/views/mobile/couponZone"),
    meta: { pagetitle: "速调吧-卡券专区", requiresAuth: true }
  },
  {
    path: "/mobile/exchangeList",
    name: "mobileExchangeList",
    component: () => import("@/views/mobile/exchangeList"),
    meta: { pagetitle: "速调吧-兑换记录", requiresAuth: true }
  },
  {
    path: "/mobile/answeringHistory",
    name: "mobileAnsweringHistory",
    component: () => import("@/views/mobile/answeringHistory"),
    meta: { pagetitle: "速调吧-答题历史", requiresAuth: true }
  },
  {
    path: "/mobile/myInvite",
    name: "mobileMyInvite",
    component: () => import("@/views/mobile/myInvite"),
    meta: { pagetitle: "速调吧-我的邀请", requiresAuth: true }
  }
];
