<template>
  <div class="tabbar">
    <div
      class="tabbar-item"
      v-for="(item, index) in list"
      :key="item.name"
      @click="goUrl(item.path)"
    >
      <img :src="tabbarType === index ? item.urlActive : item.url" />
      <span>{{ item.name }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tabbarType: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      list: [
        {
          name: "首页",
          url: require("@/assets/image/icon1.png"),
          urlActive: require("@/assets/image/icon1-active.png"),
          path: "/mobile/home"
        },
        {
          name: "兑换",
          url: require("@/assets/image/icon2.png"),
          urlActive: require("@/assets/image/icon2-active.png"),
          path: "/mobile/exchange"
        },
        {
          name: "我的",
          url: require("@/assets/image/icon3.png"),
          urlActive: require("@/assets/image/icon3-active.png"),
          path: "/mobile/my"
        }
      ]
    };
  },
  methods: {
    goUrl(path) {
      this.$router.push(path);
    }
  }
};
</script>
<style lang="scss" scoped>
.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 50px;

  .tabbar-item {
    width: 33%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 27px;
      height: 26px;
      margin-top: 5px;
      margin-bottom: 0px;
    }

    color: #232323;
    font-size: 12px;
  }
}
</style>
