import Vue from "vue";
import "src/plugins";
import "./registerServiceWorker";

import ElementUI from "element-ui";

import VueI18n from "vue-i18n";
import "./element-variables.scss";
import "./assets/icon/iconfont.css";
// 公共样式
import "@/assets/css/common.css";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import VueClipboard from "vue-clipboard2";
import "./directive";
import { isMobile } from "./common/util.js";
import "./common/gt.js";

if (isMobile()) {
  //window.location.href = "/wechat/default.aspx";
}

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?0ca70d84caa1bb17c929977abd7a3f78";
  var script = document.getElementsByTagName("script")[0];
  script.parentNode.insertBefore(hm, script);
})();

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(VueClipboard);

// 注册全局组件
import BgTop from "@/components/bgTop.vue";
import Tabbar from "@/components/tabbar.vue";
Vue.component("BgTop", BgTop);
Vue.component("Tabbar", Tabbar);

import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);

const language = process.env.VUE_APP_I18N || "zh-CN";
const eleLangs = require(`element-ui/lib/locale/lang/${language}`);
const customLangs = require(`assets/i18n/${language}.json`);
const agrees = require(`assets/i18n/agreements_${language}.js`);

const i18n = new VueI18n({
  locale: language, // 设置语言
  messages: {
    [language]: {
      ...customLangs,
      ...eleLangs.default,
      ...agrees
    }
  } // 语言包
});

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

new Vue({
  router,
  store,
  render: h => h(App),
  //这里与vue.config.js中的事件名相对应
  mounted() {
    document.dispatchEvent(new Event("render-event"));
  },
  i18n
}).$mount("#app");
